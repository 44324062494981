import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Toolbar, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pedidos from './componentes/Pedidos.jsx';
import Menu from './componentes/Menu.jsx';
import Personal from './componentes/Personal.jsx';
import Reporte from './componentes/Reportes.jsx';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: '#93E1D8',
                },
            },
        },
    },
});


const PuntoDeVenta = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const nombre = sessionStorage.getItem('Nombre');
    const rol = sessionStorage.getItem('rol');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const cerrarSesion = () => {
        sessionStorage.clear();
        navigate('/');
    }

    useEffect(() => {
        if (rol === null) {
            navigate('/');
        }
    }, [rol, navigate]);

    return (
        <>
            {rol !== null ?
                <ThemeProvider theme={theme}>
                    <Box
                        className="min-h-screen bg-cover bg-center" // Clases de Tailwind para fondo y altura de pantalla completa
                        sx={{
                            flexGrow: 1,
                            minHeight: '100vh',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        {/* Barra superior */}
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#0F1A20' }}>
                                    Usuario: {nombre}
                                </Typography>
                                <LogoutIcon sx={{ color: '#FF0004' }} onClick={() => cerrarSesion()} />
                            </Toolbar>
                        </AppBar>

                        {/* Tabs responsivos */}
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="tabs responsivos"
                            sx={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                '& .MuiTabs-flexContainer': {
                                    justifyContent: 'center',
                                },
                            }}
                            className="border-b border-gray-300" // Borde inferior con Tailwind
                        >
                            <Tab label="PEDIDOS" className="text-sm sm:text-base" />  {/* Responsivo para texto pequeño */}
                            {rol === 'Admin' || rol === 'Full' ? <Tab label="MENÚ" className="text-sm sm:text-base" /> : null}
                            {rol === 'Admin' || rol === 'Full' ? <Tab label="PERSONAL" className="text-sm sm:text-base" /> : null}
                            {rol === 'Admin' || rol === 'Full' ? <Tab label="REPORTES" className="text-sm sm:text-base" /> : null}
                        </Tabs>

                        {/* Contenido de los tabs con scroll */}
                        <Box
                            className="max-h-[80vh] overflow-y-auto p-2 sm:p-4" // Clases para altura, scroll y padding responsivo
                            sx={{
                                maxHeight: '80vh',
                                overflowY: 'auto',
                                padding: 2,
                            }}
                        >
                            {tabValue === 0 && <Pedidos />}
                            {tabValue === 1 && <Menu />}
                            {tabValue === 2 && <Typography><Personal /></Typography>}
                            {tabValue === 3 && <Typography><Reporte /></Typography>}
                        </Box>
                    </Box>
                </ThemeProvider>
                : null}
        </>
    );
};

export default PuntoDeVenta;
