import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Avatar } from '@mui/material';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

// Componente UserCard separado y optimizado
const UserCard = React.memo(({ name, role, avatarLetter }) => {

    return (
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, boxShadow: 3, marginBottom: 2 }}>
            <Avatar sx={{ bgcolor: colores[Math.floor(Math.random() * colores.length)], width: 56, height: 56, color: 'black' }}>
                {avatarLetter}
            </Avatar>
            <CardContent sx={{ marginLeft: 2 }}>
                <Typography variant="h6" component="div">{name}</Typography>
                <Typography variant="body2" color="text.secondary">{role}</Typography>
            </CardContent>
        </Card>
    );
});

const colores = [
    '#272838', '#93E1D8', '#BC4749', '#8E7DBE', '#CBB3BF',
    '#FE938C', '#EDAF97', '#C49792', '#AD91A3', '#9D91A3',
    '#7CC6FE', '#5DFDCB', '#F4FAFF', '#8789C0', '#F8F272',
    '#C5A48A'
];

const Personal = () => {
    const [puesto, setPuesto] = useState('');
    const [nombre, setNombre] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [personal, setPersonal] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    useEffect(() => {
        cargarUsuarios();
    }, []);

    const cargarUsuarios = () => {
        axios.get('https://mariscosdelvalle.com.mx/apis/cargarPersonal.php', {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
            .then((response) => {
                setPersonal(response.data);
            })
            .catch((error) => console.error(error));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({ puesto, nombre, contrasena, email });
        if (puesto === '' || nombre === '' || contrasena === '' || email === '') {
            alert('Datos faltantes');
        } else {
            alert('Datos completos');
            const data = {
                Nombre: nombre,
                Usuario: email,
                Nip: contrasena,
                Rol: puesto
            };
            const pedidoJSON = JSON.stringify(data);
            axios.post('https://mariscosdelvalle.com.mx/apis/agregarUsuario.php', pedidoJSON, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then((response) => {
                    console.log(response.data);
                    setPuesto('');
                    setNombre('');
                    setContrasena('');
                    setEmail('');
                    setOpen(false);
                    cargarUsuarios();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        // Limpiar los campos después de enviar (opcional)
        /* setPuesto('');
        setNombre('');
        setContrasena('');
        setEmail(''); */
    };

    // Funciones de manejo de cambios
    const handlePuestoChange = useCallback((e) => setPuesto(e.target.value), []);
    const handleNombreChange = useCallback((e) => setNombre(e.target.value), []);
    const handleContrasenaChange = useCallback((e) => setContrasena(e.target.value), []);
    const handleEmail = useCallback((e) => setEmail(e.target.value), []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '80%', md: 400, lg: 400 },
        bgcolor: 'background.paper',
        borderRadius: 4,
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {personal.map((array, index) => (
                        <UserCard
                            key={index}
                            name={array.Nombre}
                            role={array.Rol === 'Admin' ? 'Administrador' : array.Rol === 'Full' ? 'Gerente' : 'Camarero'}
                            avatarLetter={array.Nombre[0]}
                        />
                    ))}
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: '9px' }}>
                <Grid item>
                    <Button onClick={handleOpen} variant="outlined" color="success" >Agregar Usuario</Button>
                </Grid>
            </Grid>

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">Nuevo Usuario</Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="select-role-label">Puesto</InputLabel>
                            <Select
                                labelId="select-role-label"
                                value={puesto}
                                onChange={handlePuestoChange}
                            >
                                <MenuItem value="Admin">Administrador</MenuItem>
                                <MenuItem value="Mesero">Mesero</MenuItem>
                                <MenuItem value="Barra">Barra</MenuItem>
                                <MenuItem value="Cocina">Cocina</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Nombre"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={nombre}
                            onChange={handleNombreChange}
                        />

                        <TextField
                            label="Correo/Usuario"
                            type="email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={handleEmail}
                        />

                        <TextField
                            label="Contraseña"
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={contrasena}
                            onChange={handleContrasenaChange}
                        />

                        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, width: '100%' }}>
                            Guardar
                        </Button>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default Personal;
